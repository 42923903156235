<template>
  <div class="py-15 px-7">
    <v-row>
      <v-col
        cols="12"
        class="d-flex justify-end pb-6 px-8"
        v-if="$vuetify.breakpoint.smAndUp"
      >
        <v-btn
          color="#38227A"
          dark
          class="px-7 text-capitalize"
          style="border-radius: 10px"
          height="45px"
          @click="toggleAudienceModal({ show: true, type: 'add' })"
          v-if="this.adminAccess == 'Super Admin'"
        >
          <span>Compose Manual Email</span>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        class="d-flex justify-end pb-6 px-8"
        v-if="$vuetify.breakpoint.xsOnly"
      >
        <v-menu offset-y v-if="this.adminAccess == 'Super Admin'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="mobile-menu"
              >mdi-menu</v-icon
            >
          </template>
          <v-list>
            <v-list-item-group active-class="active-class">
              <v-list-item
                @click="toggleAudienceModal({ show: true, type: 'add' })"
              >
                <v-list-item-title>Compose Manual Email</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-data-table
      fixed-header
      hide-default-footer
      :headers="headers"
      :items="items"
      :loading="loading"
      class="elevation-8"
      :options.sync="dataOptions"
      loading-text="Loading... Please wait"
      mobile-breakpoint="0"
      :items-per-page="tableInfo[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]"
      :server-items-length="
        tableInfo[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
      "
    >
      <template v-slot:top> </template>
      <template v-slot:item="props">
        <tr>
          <td class="text-center">
            <div class="tableItem">{{ props.item.date }}</div>
            <div class="tableItem">{{ props.item.time }}</div>
          </td>
          <td class="text-center">
            <div class="tableItem">{{ props.item.email_subject }}</div>
          </td>
          <td class="text-center">
            <div class="tableItem">
              <span style="color: #757575">{{
                props.item.total_recipients
              }}</span>
            </div>
          </td>
          <td class="text-center py-4">
            <div class="tableItem">{{ props.item.sent_to }}</div>
          </td>
          <!--  <td class="text-center py-4">
            <div
              class="tableItem"
              v-for="(item, i) in props.item.audience"
              :key="i"
            >
              {{ item }}
            </div>
          </td>-->
          <td class="text-center">
            <v-chip class="actionColumn-btn" @click="route(props.item)">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="25"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>view</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
      </template>
      <template v-slot:footer>
        <v-divider></v-divider>
        <v-row no-gutters class="pt-6">
          <v-col
            class="mb-4"
            align-self="center"
            cols="12"
            xs="12"
            sm="6"
            md="4"
          >
            <div
              class="justify-center justify-md-start d-flex px-5 tableHeader-text"
            >
              <div class="alignSelf-center mr-3 pt-2">Show</div>
              <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                <v-select
                  class=""
                  :items="itemsPerPageList"
                  hide-details
                  v-model="
                    tableInfo[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
                  "
                  dense
                ></v-select>
              </div>

              <div class="alignSelf-center pt-2">
                {{ tableInfo[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER] }}
                of
                {{ tableInfo[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE] }}
              </div>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="4" md="4">
            <div>
              <v-pagination
                v-model="tableInfo[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]"
                :length="tableInfo[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]"
                :total-visible="5"
                color="#D30024"
                class="pagination"
              >
              </v-pagination>
            </div>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <audience-modal></audience-modal>
  </div>
</template>
<script>
import {
  DATA,
  PAGE_INFO,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
  SORTING_KEY,
} from "@/constants/APIKeys";
import Axios from "@/api/BaseAxios";
import { API_BULK_GAME_EMAIL_LIST } from "@/constants/APIUrls";
import { mapActions, mapGetters } from "vuex";
import { ROUTER_URL } from "@/constants/urls";
export default {
  name: "BulkEmails",
  components: {
    AudienceModal: () =>
      import("./BulkEmailAudienceModal/BulkEmailAudienceGameModal"),
  },
  data() {
    return {
      adminAccess: localStorage.getItem("adminAccess"),
      dataOptions: { sortBy: [], sortDesc: [] },
      items: [],
      loading: false,
      tableInfo: {
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 5,
          [ITEMS_PER_PAGE]: 10,
        },
      },
      itemsPerPageList: [10, 15, 20, 30, 50, 100],
      headers: [
        {
          sortable: true,
          text: "Date/Time",
          align: "center",
          value: "date",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Email Content",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Email Stats",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Audience",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Action",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
      audienceData: [
        { key: false, value: "host", text: "Host" },
        { key: false, value: "home_team", text: "Home Team" },
        { key: false, value: "committee", text: "Committee" },
      ],
      [SORTING_KEY]: "",
    };
  },
  computed: {
    ...mapGetters({
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    layout() {
      return this.$route.meta.layout;
    },
  },
  watch: {
    "tableInfo.page_info.page_number": function () {
      this.getBulkEmailList();
    },
    "tableInfo.page_info.detail_count": function () {
      this.getBulkEmailList();
    },
    "dataOptions.sortBy": function () {
      console.log(this.dataOptions);
      if (this.dataOptions.sortBy.length !== 0) {
        this.tableInfo[SORTING_KEY] = this.dataOptions.sortDesc[0]
          ? `-${this.dataOptions.sortBy[0]}`
          : this.dataOptions.sortBy[0];
        this.getBulkEmailList();
      } else {
        this.tableInfo[SORTING_KEY] = "";
        this.getBulkEmailList();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleAudienceModal: "emailSettings/toggleModal",
      toggleEmailDetail: "emailSettings/toggleEmailDetail",
    }),
    route(item) {
      let gameDetail = {};
      let audienceDetail = {};
      let emailContent = {};
      gameDetail = { ...item.game_detail };
      let audience = [];
      let index = this.audienceData.findIndex(
        (item) => item.value === audience
      );
      audienceDetail = { ...this.audienceData[index] };
      emailContent = {
        subject: item.email_subject,
        body: item.email_body,
      };

      let emailDetail = {
        game: gameDetail,
        audience: audienceDetail,
        email: emailContent,
      };
      this.toggleEmailDetail(emailDetail);
      if (this.layout === "CampaignDashboardLayout") {
        this.$route.query["type"] = "view";
        this.$root.$refs.bulkEmail.changeTab(false);
      } else {
        this.$router.push({
          name: ROUTER_URL.gamePanel.children.bulkGameEmailForm.name,
          query: { type: "view" },
        });
      }
    },
    getBulkEmailList() {
      this.loading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.items = res.data.bulk_email_list;
        this.tableInfo[PAGE_INFO][PAGE_NUMBER] =
          res[DATA][PAGE_INFO][PAGE_NUMBER];
        this.tableInfo[PAGE_INFO][TOTAL_PAGE] =
          res[DATA][PAGE_INFO][TOTAL_PAGE];
        // this.tableInfo[PAGE_INFO][ITEMS_PER_PAGE] =
        //   res[DATA][PAGE_INFO][ITEMS_PER_PAGE];
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData[ITEMS_PER_PAGE] = this.tableInfo[PAGE_INFO][ITEMS_PER_PAGE];
      formData[PAGE_NUMBER] = this.tableInfo[PAGE_INFO][PAGE_NUMBER];
      formData[SORTING_KEY] = this.tableInfo[SORTING_KEY];
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        API_BULK_GAME_EMAIL_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    this.getBulkEmailList();
  },
};
</script>
<style scoped>
* {
  font-family: Lato;
}
span.v-chip {
  border-radius: 4px;
}
.v-btn >>> span {
  letter-spacing: 0px;
  font-weight: 600;
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
span.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
  height: 45px;
  width: 38px;
  cursor: pointer;
}
.v-data-table.elevation-8.v-data-table--fixed-header.theme--light {
  border-radius: 10px;
}
.pagination {
  float: right;
}
.tableItem {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: black;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
